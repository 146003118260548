<template>
    <nav>
        <div class="logo">
            <img src="@/assets/loader.svg" alt="">
        </div>
        <Select_input class="language select" :options="languages_as_select_options" v-model="language" />
        <router-link v-for="(item, index) in get_menu" :to="item.link" :key="index">{{ item.name }}</router-link>
        <a href="#" @click="logout">Выход</a>
    </nav>
</template>

<script>
import {mapGetters} from "vuex";
import request from "@/functions/Fetch";
import Select_input from "@/components/Select_input.vue";

export default {
    name: "Top_menu",
    components: {
        Select_input
    },
    data() {
        return {
            language: "",
            languages: [
                "ru",
                "kz",
                "en",
                "dt"
            ]
        }
    },
    computed: {
        ...mapGetters([
            "get_menu",
            "get_language"
        ]),
        languages_as_select_options() {
            return this.languages.map(val => {
                return {
                    name: val.toUpperCase(),
                    value: val
                }
            });
        }
    },
    watch: {
        language() {
            this.$store.commit("set_language", this.language);
            let current_route = this.$router.currentRoute.value.path;
            this.$store.commit("set_loader", true);
            this.$router.push("/");
            this.$nextTick(() => {
                setTimeout(() => {
                    this.$router.push(current_route);
                    this.$store.commit("set_loader", false);
                }, 100);
            });
        }
    },
    methods: {
        logout(event) {
            this.$store.commit("set_loader", true);
            event.preventDefault();
            request(`${process.env.VUE_APP_API_PATH}/logout`)
                .then(() => {
                    this.$store.commit("unset_user");
                    this.$store.commit("set_loader", false);
                    this.$router.push("/");
                });
        }
    },
    mounted() {
        this.language = this.get_language;
    }
}
</script>

<style lang="less" scoped>
nav {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    box-shadow: 0 0 5px fade(#000, 50%);
    padding: 1rem;
    transform: translateY(-100%);
    background: #fff;
    z-index: 2;
    @keyframes show {
        from {
            transform: translateY(-100%);
        }
        to {
            transform: translateY(0);
        }
    }
    animation: show .5s .5s forwards;
    .logo {
        img {
            width: 2rem;
            height: 2rem;
        }
    }
    .select.language {
        margin-bottom: 0;
    }
    a {
        color: #1d1d1d;
        text-decoration: none;
        &.router-link-exact-active, &:hover {
            color: #42b983;
        }
    }
}
</style>