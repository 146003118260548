<template>
    <div class="select">
        <label>
            <span v-if="label">{{ label }}</span>
            <select :value="modelValue" @change="$emit('update:modelValue', isNaN($event.target.value)? $event.target.value: +$event.target.value)">
                <option v-for="(option, index) in options" :key="index" :value="option.value">{{ option.name }}</option>
            </select>
        </label>
    </div>
</template>

<script>
export default {
    name: "Select_input",
    props: {
        label: String,
        options: Array,
        modelValue: {
            required: true
        }
    },
}
</script>

<style lang="less" scoped>
.select:not(:last-child) {
    margin-bottom: 1.5rem;
}
label, span, select {
    display: block;
    font-family: sans-serif;
    width: 100%;
    font-size: 1rem;
    color: #1d1d1d;
}
span {
    margin-bottom: .5rem;
}
select {
    padding: .25rem .5rem;
    outline: none;
    border: 1px solid #1d1d1d;
    box-sizing: border-box;
    &:focus {
        border-color: #42b983;
    }
    option {
        padding: .25rem 0;
    }
}
</style>