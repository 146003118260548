<template>
    <div class="popup" :class="{ active: get_loader }">
        <div class="center">
            <Loader_icon />
        </div>
    </div>
</template>

<script>
import Loader_icon from "@/components/icons/Loader.vue";
import {mapGetters} from "vuex";
export default {
    name: "Popup_loader",
    components: {
        Loader_icon
    },
    computed: {
        ...mapGetters([
            "get_loader"
        ])
    }
}
</script>

<style lang="less" scoped>
.popup {
    position: fixed;
    inset: 0;
    z-index: 999;
    display: none;
    background: fade(#fff, 75%);
    &.active {
        display: flex;
    }
    .center {
        margin: auto;
    }
}
</style>