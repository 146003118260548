<template>
    <Top_menu v-if="get_user" />
    <router-view v-slot="{ Component }">
        <div :class="{ outer_container: $route.path !== '/' }" :key="$route.path">
            <component :is="Component"/>
        </div>
    </router-view>
    <Popup_loader />
    <Popup_notify />
</template>

<script>
import Top_menu from "@/components/Top_menu.vue";
import {mapGetters} from "vuex";
import Popup_loader from "@/components/Popup_loader.vue";
import Popup_notify from "@/components/Popup_notify.vue";

export default {
    components: {
        Top_menu,
        Popup_loader,
        Popup_notify
    },
    computed: {
        ...mapGetters([
            "get_user"
        ])
    }
}
</script>

<style lang="less">
body, html {
    margin: 0;
    img {
        display: block;
    }
    * {
        margin: 0;
    }
}
#app {
    font-family: sans-serif;
    color: #1d1d1d;
    min-height: 100dvh;
}
.outer_container {
    padding-top: 4rem;
    .wrapper {
        padding: 1rem;
        box-sizing: border-box;
    }
}
</style>
