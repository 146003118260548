<template>
    <div class="popup" :class="{ active: get_notify.state }" @click="close_popup">
        <div class="center">
            <p>{{ get_notify.message }}</p>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Popup_notify",
    computed: {
        ...mapGetters([
            "get_notify"
        ])
    },
    methods: {
        close_popup() {
            this.$store.commit("set_notify", {
                state: false,
                message: ""
            });
        }
    }
}
</script>

<style lang="less" scoped>
.popup {
    position: fixed;
    inset: 0;
    z-index: 999;
    display: none;
    background: fade(#fff, 75%);
    &.active {
        display: flex;
    }
    .center {
        margin: auto;
        min-width: 300px;
        max-width: 80vw;
        max-height: 80vh;
        overflow: auto;
        background: #fff;
        padding: 1rem;
        box-shadow: 0 3px 5px fade(#000, 25%);
        p {
            font-family: sans-serif;
            font-size: 1rem;
            margin: 0;
            text-align: center;
        }
    }
}
</style>