import store from "@/store";

export default async function request(url, data = {}, method = "POST") {
    let response;
    let language = store.getters.get_language;
    if (method === "POST") {
        let form = new FormData();
        form.append("language", language);
        for (let key of Object.keys(data)) {
            form.append(key, data[key]);
        }
        response = await fetch(url, {
            method: method,
            body: form,
            credentials: "include"
        });
    }
    else {
        if (Object.keys(data).length) {
            data.language = language;
            let params = new URLSearchParams(data);
            response = await fetch(`${url}?${params.toString()}`, {
                method: method,
                credentials: "include"
            });
        }
        else {
            response = await fetch(`${url}`, {
                method: method,
                credentials: "include"
            });
        }
    }
    if (response.status === 200) {
        response = await response.json();
        return response;
    }
    else {
        console.log(response);
    }
    return false
}
