<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        width="237"
        height="235"
        viewBox="0 0 237 235"
    >
    <g>
        <polygon
            fill="#171C29"
            points="236.8000030517578,0 236.8000030517578,234.60000304877758 0,234.60000304877758 0,0 184.39999389648438,182.60000304877758 184.39999389648438,0 "
        />
    </g>
</svg>
</template>

<script>
export default {
    name: "Loader_icon"
}
</script>

<style lang="less" scoped>
svg {
    width: 4rem;
    height: 4rem;
    display: block;
    @keyframes outer_loader {
        from {
            transform: scale(1);
        }
        to {
            transform: scale(1.1);
        }
    }
    animation: outer_loader .75s infinite alternate;
    polygon {
        @keyframes inner_loader {
            from {
                fill: #171C29;
            }
            to {
                fill: #42b983;
            }
        }
        animation: inner_loader .75s infinite alternate;
    }
}
</style>