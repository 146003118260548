import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import request from "@/functions/Fetch";

request(`${process.env.VUE_APP_API_PATH}/get_user`)
    .then(json => {
        if (json) {
            store.commit("set_user", json.detail);
            request(`${process.env.VUE_APP_API_PATH}/get_menu`)
                .then(json => {
                    store.commit("set_menu", json.detail);
                    if (router.currentRoute.path === "/") {
                        router.push(store.getters.get_admin_home);
                    }
                })
        }
        createApp(App).use(store).use(router).mount('#app');
    });
