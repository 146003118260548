import {createRouter, createWebHashHistory} from 'vue-router';
import store from "@/store";

const routes = [
    {
        path: '/',
        meta: {
            title: "Вход"
        },
        component: () => import("@/views/Login.vue")
    },
    {
        path: '/stats',
        meta: {
            title: "Статистика"
        },
        component: () => import("@/views/Stats.vue")
    },
    {
        path: '/repertoire',
        meta: {
            title: "Репертуар"
        },
        component: () => import("@/views/Repertoire.vue")
    },
    {
        path: '/main',
        meta: {
            title: "Главная"
        },
        component: () => import("@/views/Main.vue")
    },
    {
        path: '/actors',
        meta: {
            title: "Актёры"
        },
        component: () => import("@/views/Actors.vue")
    },
    {
        path: '/spectacles',
        meta: {
            title: "Спектакли"
        },
        component: () => import("@/views/Spectacles.vue")
    },
    {
        path: '/vacancy',
        meta: {
            title: "Вакансии"
        },
        component: () => import("@/views/Vacancy.vue")
    },
    {
        path: '/news',
        meta: {
            title: "Новости"
        },
        component: () => import("@/views/News.vue")
    },
    {
        path: '/pages',
        meta: {
            title: "Страницы"
        },
        component: () => import("@/views/Pages.vue")
    },
    {
        path: '/common',
        meta: {
            title: "Общее"
        },
        component: () => import("@/views/Common.vue")
    },
    {
        path: '/users',
        meta: {
            title: "Пользователи"
        },
        component: () => import("@/views/Users.vue")
    },
    {
        path: '/settings',
        meta: {
            title: "Настройки"
        },
        component: () => import("@/views/Settings.vue")
    },
    {
        path: '/emails',
        meta: {
            title: "Рассылка"
        },
        component: () => import("@/views/Emails.vue")
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach((to) => {
    if (!store.getters.get_user && to.path !== "/") {
        return "/";
    }
    if (store.getters.get_user && to.path === "/") {
        return store.getters.get_admin_home;
    }
});

export default router;
