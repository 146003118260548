import {createStore} from 'vuex';
import Constants from "@/functions/Constants";

export default createStore({
    state: {
        user: null,
        menu: [],
        loader: false,
        notify: {
            message: "",
            state: false
        },
        language: "ru"
    },
    getters: {
        get_user(state) {
            return state.user;
        },
        get_menu(state) {
            return state.menu;
        },
        get_loader(state) {
            return state.loader;
        },
        get_notify(state) {
            return state.notify;
        },
        get_language(state) {
            return state.language;
        },
        get_admin_home(state) {
            switch (state.user) {
                case Constants.role_admin: {
                    return "/stats";
                }
                case Constants.role_hr: {
                    return "/vacancy";
                }
                case Constants.role_pr: {
                    return "/news";
                }
                case Constants.role_sales: {
                    return "/repertoire";
                }
            }
        }
    },
    mutations: {
        unset_user(state) {
            state.user = null;
        },
        set_user(state, value) {
            state.user = value;
        },
        set_menu(state, value) {
            state.menu = value;
        },
        set_loader(state, value) {
            state.loader = value;
        },
        set_notify(state, value) {
            state.notify = value;
        },
        set_language(state, value) {
            state.language = value;
        },
    },
    actions: {},
    modules: {}
});
